import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Crea2 from './page/integration1';
import Crea3 from './page/integration2';
import Home from './page/home';

function App() {
  return (
    <>
    
    
    <Router> 
    
        <Routes> 

             <Route path="/" element={<Home />}  /> 
             <Route path="/crea2" element={<Crea2 />}  /> 
             <Route path="/crea3" element={<Crea3 />}  /> 
        </Routes> 
     
</Router> 
    
    </>
)}

export default App;
