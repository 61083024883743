import React from "react";
import { NavLink } from "react-router-dom";


const Home=()=>{

    return(
        <>
        <div className="flex flex-col">

<NavLink to="/crea2">
    crea2
</NavLink>
<NavLink to="/crea3">
    crea3
</NavLink>
        </div>

        </>
    )
}

export default Home